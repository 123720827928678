import { FC } from 'react';

import { Flex, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import Table from '@/components/RTable';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';
import { formatNumberWithCommas } from '@/utils';

import { StyledTable } from './styles';

interface EnergyTableDataTypes {
  key: string;
  title: string | JSX.Element;
  value: string;
  earnedAmount?: string;
}

interface EnergyTableProps {
  simulationData: SimulationResult;
}

const EnergyTable: FC<EnergyTableProps> = ({ simulationData }) => {
  const theme = useTheme();

  const energyDataTable: EnergyTableDataTypes[] = [
    {
      key: '1',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Sales Revenue with Storage
          </Typography.Text>
          <Tooltip
            title="Sales Revenue with Storage"
            description="Revenue from energy sales, including earnings from both storage and plant operations, as well as additional income from storage services like ancillary services and arbitrage."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      value:
        formatNumberWithCommas(
          Number(simulationData.result.totalRevenueFromSale.toFixed(0))
        ) + ' $',
      earnedAmount:
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitOfBatteryFromSale.toFixed(0))
        ) + ' $',
    },
    {
      key: '2',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Cost of Purchased Energy for Sale
          </Typography.Text>
          <Tooltip
            title="Cost of Purchased Energy for Sale"
            description="Expense incurred for buying energy that is intended for resale."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      value:
        formatNumberWithCommas(
          Number(simulationData.result.costOfPurchasedEnergy.toFixed(0))
        ) + ' $',
    },
    {
      key: '3',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Gross Profit from Sale (With Storage)
          </Typography.Text>
          <Tooltip
            title="Gross Profit from Sale (With Storage)"
            description="Profitability of the core business operations, including storage services, before accounting for other expenses."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      value:
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitFromSale?.toFixed(0) || 0)
        ) + ' $',
    },
    {
      key: '4',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Gross Profit from Sale (Without Storage)
          </Typography.Text>
          <Tooltip
            title="Gross Profit from Sale (Without Storage)"
            description="Profitability of the core business operations, excluding storage services, before accounting for other expenses."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      value:
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitWos.toFixed(0))
        ) + ' $',
    },
  ];

  const energyColumns = [
    {
      dataIndex: 'title',
      width: '28%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">Value</Typography.Text>
        </Flex>
      ),
      dataIndex: 'value',
      key: 'value',
      width: '36%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">
            Earned Amount
          </Typography.Text>
          <Tooltip
            title="Earned Amount"
            description="Shows how much extra profit storage batteries contribute to gross profit."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      dataIndex: 'earnedAmount',
      key: 'earnedAmount',
      width: '36%',
      onCell: (_record: EnergyTableDataTypes, rowIndex?: number) => {
        if (rowIndex === 0) {
          return {
            rowSpan: energyDataTable.length,
            style: {
              verticalAlign: 'middle',
              fontSize: '20px',
            },
          };
        } else {
          return { rowSpan: 0 };
        }
      },
      render: (text: string) => ({
        children: <span>{text}</span>,
      }),
    },
  ];
  return (
    <StyledTable>
      <Table
        dataSource={energyDataTable}
        columns={energyColumns}
        pagination={false}
      />
    </StyledTable>
  );
};

export default EnergyTable;
