import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormProps,
  notification,
  Row,
  Typography,
} from 'antd';
import Lottie from 'lottie-react';
import useSWR from 'swr';

import { scenarioApi } from '@/api';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import Card from '@/components/RCard';
import RCollapse, { ExtendedCollapsePanelProps } from '@/components/RCollapse';
import RNotificationCard from '@/components/RNotificationCard';
import { AnimatedText } from '@/components/RTextAnimation';
import RUsageLimitIndicator from '@/components/RUsageLimitIndicator';
import { Draft, DrawerType } from '@/types/global';
import {
  participationStatusOptions,
  Scenario,
  ScenarioForm,
} from '@/types/scenario';
import { getParticipationStatusLabel, handleError } from '@/utils';

import { StyledDrawer } from './styles';
import BatteryOptions from '../Form/BatteryOptions';
import Description from '../Form/Description';
import ElectricityMarketParticipation from '../Form/ElectricityMarketParticipation';
import FinancialMetrics from '../Form/FinancialMetrics';
import PricesAndLoadProfile from '../Form/PricesAndLoadProfile';

type Props = {
  drawerOptions: DrawerType<Scenario>;
  setDrawerOptions: (options: DrawerType<Scenario>) => void;
  mutate?: () => void;
  maxDataCount?: number;
  currentDataCount?: number;
};

type ScenarioFormKeys = keyof ScenarioForm;

const ScenarioDrawer = (props: Props) => {
  const {
    drawerOptions,
    setDrawerOptions,
    mutate,
    maxDataCount,
    currentDataCount,
  } = props;
  const { type, data: selectedScenario } = drawerOptions;
  const { data } = useSWR<Scenario>(
    selectedScenario?.id ? `/scenario/${selectedScenario?.id}/` : null,
    {
      revalidateOnFocus: false,
    }
  );
  const [form] = Form.useForm();
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [duplicateButtonDisabled, setDuplicateButtonDisabled] =
    useState<boolean>(true);

  const initialFormValues: ScenarioForm | undefined = useMemo(() => {
    if (data) {
      return {
        ...data,
        pfcParticipation: participationStatusOptions.find(
          (option) =>
            option.label ===
            getParticipationStatusLabel(Number(data.pfcParticipation))
        )?.value,
        sfcParticipation: participationStatusOptions.find(
          (option) =>
            option.label ===
            getParticipationStatusLabel(Number(data.sfcParticipation))
        )?.value,
        gridActions: [
          data.sellToGrid && 'sellToGrid',
          data.purchaseFromGrid && 'purchaseFromGrid',
        ].filter(Boolean) as string[],
      };
    }
  }, [data]);

  const [draft, setDraft] = useState<Draft>({
    draftId: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const [uploadError, setUploadError] = useState<string | null>('');

  useEffect(() => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
      setDuplicateButtonDisabled(true);
    }
  }, [initialFormValues, form]);

  const hasUnsavedData = () => {
    const fields: Partial<ScenarioForm> = form.getFieldsValue(true);
    const isFormDirty = form.isFieldsTouched();

    if (!initialFormValues) return false;
    const unsavedFields = (Object.keys(fields) as ScenarioFormKeys[]).filter(
      (key) => {
        if (key === 'pricesAndLoad') {
          return (
            typeof fields[key] !== 'string' &&
            fields[key] !== initialFormValues[key]
          );
        }
        return fields[key] !== initialFormValues[key];
      }
    );

    return unsavedFields.length > 0 && isFormDirty;
  };

  const onClose = () => {
    if (form.isFieldsTouched()) {
      if (hasUnsavedData()) {
        setNotificationCardVisible(true);
      } else {
        setDrawerOptions({
          type: null,
          data: null,
        });
      }
    } else {
      setDrawerOptions({
        type: null,
        data: null,
      });
    }
  };

  const title =
    drawerOptions.type === 'view'
      ? `View Scenario`
      : drawerOptions.type === 'duplicate'
        ? `Duplicate Scenario`
        : '';
  const description =
    drawerOptions.type === 'view'
      ? `You can view and duplicate details of "${data?.name}" scenario.`
      : drawerOptions.type === 'duplicate'
        ? `You can view and duplicate details of "${data?.name}" scenario.`
        : '';

  const resetDrawerData = () => {
    form.resetFields();
    form.setFieldsValue(initialFormValues);
    setDuplicateButtonDisabled(true);
  };

  const overlayTextArray = useMemo(() => {
    return [
      'Configuring scenario settings.',
      'Analyzing file...',
      'The final touches are being made.',
    ];
  }, []);

  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, isLoading]);

  const onFinish = async () => {
    const values: ScenarioForm = form.getFieldsValue(true);
    const scenarioId = data?.id;

    const requiredValues = [
      values.name,
      values.modelType,
      values.pfcParticipation,
      values.sfcParticipation,
      values.whenBatteryIsDepleted,
      values.taxRate,
      values.hurdleRate,
      values.batteryPriceDeclinePercentagePerYear,
    ];

    if (
      values.whenBatteryIsDepleted !== 'stop_simulation' &&
      values.whenBatteryIsDepleted !== 'continue_wo_storage'
    ) {
      requiredValues.push(values.replacementNumberLimit);
    }

    if (requiredValues.some((value) => value == null)) {
      throw new Error('Some required values are undefined.');
    }

    if (!scenarioId) {
      throw new Error('Scenario ID is undefined.');
    }

    try {
      setIsLoading(true);
      const payload = {
        name: values.name,
        description: values.description,
        pricesAndLoad:
          typeof values.pricesAndLoad === 'string'
            ? undefined
            : values.pricesAndLoad,
        sellToGrid: values.gridActions?.includes('sellToGrid') || false,
        purchaseFromGrid:
          values.gridActions?.includes('purchaseFromGrid') || false,
        unitDegradation: values.unitDegradation,
        whenBatteryIsDepleted: values.whenBatteryIsDepleted,
        replacementNumberLimit: values.replacementNumberLimit,
        modelType: values.modelType,
        taxRate: values.taxRate,
        batteryPriceDeclinePercentagePerYear:
          values.batteryPriceDeclinePercentagePerYear,
        hurdleRate: values.hurdleRate,
        pfcParticipation: values.pfcParticipation,
        sfcParticipation: values.sfcParticipation,
      };
      await scenarioApi.duplicateScenario(scenarioId, payload);
      notification.success({
        message: `${values.name} scenario has been created successfully!`,
      });
      setIsLoading(false);
      resetDrawerData();
      mutate && mutate();
      setNotificationCardVisible(false);
      setDrawerOptions({
        type: null,
        data: null,
      });
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const isDisabled = drawerOptions.type === 'view' ? true : false;

  const collapseItems: ExtendedCollapsePanelProps[] = [
    {
      key: 'description-section',
      header: <Typography.Text className="bold">Description</Typography.Text>,
      children: <Description disabled={isDisabled} />,
    },
    {
      key: 'electricity-market-participation-section',
      header: (
        <Typography.Text className="bold">
          Electricity Market Participation
        </Typography.Text>
      ),
      children: (
        <ElectricityMarketParticipation form={form} disabled={isDisabled} />
      ),
    },
    {
      key: 'battery-options-section',
      header: (
        <Typography.Text className="bold">Battery Options</Typography.Text>
      ),
      children: <BatteryOptions form={form} disabled={isDisabled} />,
    },
    {
      key: 'financial-metrics-section',
      header: (
        <Typography.Text className="bold">Financial Metrics</Typography.Text>
      ),
      children: <FinancialMetrics disabled={isDisabled} />,
    },
    {
      key: 'pnl-profile-section',
      header: (
        <Typography.Text className="bold">
          Prices and Load Profile
        </Typography.Text>
      ),
      children: (
        <PricesAndLoadProfile
          currentScenarioData={data}
          form={form}
          disabled={isDisabled}
          draft={draft}
          setDraft={setDraft}
          uploadError={uploadError}
          setUploadError={setUploadError}
        />
      ),
    },
  ];

  const onFieldsChange: FormProps['onFieldsChange'] = () => {
    if (initialFormValues) {
      setDuplicateButtonDisabled(false);
    }
  };

  const resetFormFieldsToInitialValues = () => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
      setDuplicateButtonDisabled(true);
      setDraft({
        draftId: undefined,
        startDate: undefined,
        endDate: undefined,
      });
      setUploadError(null);
      form.resetFields();
    }
  };

  return (
    <StyledDrawer
      className={drawerOptions.type === 'view' ? 'view-drawer' : ''}
      title={title}
      description={description}
      rightSideSection={<RUsageLimitIndicator.Scenarios />}
      destroyOnClose
      open={!!type}
      onClose={onClose}
      drawerLock={{
        isLocked: !!(
          drawerOptions.type !== 'view' && currentDataCount === maxDataCount
        ),
        lockText: 'You have reached the limit of scenarios you can create.',
      }}
    >
      <Card className="px-16 py-16 content-card">
        {initialFormValues && (
          <Form<ScenarioForm>
            onFieldsChange={onFieldsChange}
            className="form-styles"
            layout="vertical"
            initialValues={initialFormValues}
            form={form}
            onFinish={onFinish}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            }}
          >
            <RCollapse
              defaultActiveKey={collapseItems[0].key}
              items={collapseItems}
            />
            {drawerOptions.type === 'duplicate' && (
              <Row
                className="bottom-button-group"
                align="middle"
                justify="space-between"
              >
                <Button onClick={onClose}>Cancel</Button>
                {!duplicateButtonDisabled && (
                  <div className="right-side-btn-group">
                    <Button
                      onClick={resetFormFieldsToInitialValues}
                      loading={isLoading}
                      disabled={isLoading}
                      className="ml-4"
                    >
                      Reset
                    </Button>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      className="ml-4"
                      type="primary"
                      htmlType="submit"
                    >
                      Duplicate
                    </Button>
                  </div>
                )}
              </Row>
            )}
          </Form>
        )}
      </Card>
      <RNotificationCard
        title="Data will be lost."
        message="Are you sure you want to leave?"
        type="warning"
        confirmText="Leave"
        cancelText="Cancel"
        open={notificationCardVisible}
        onConfirm={() => {
          resetDrawerData();
          setNotificationCardVisible(false);
          setDrawerOptions({
            type: null,
            data: null,
          });
        }}
        onCancel={() => setNotificationCardVisible(false)}
      />
      {isLoading && (
        <Row align="top" justify="center" className="pt-120 loading-wrapper">
          <Col>
            <Lottie
              style={{ height: '120px' }}
              animationData={loadingLottie}
              loop={true}
            />
            <Row>
              <AnimatedText className="animated-text" text={overlayText} />
            </Row>
          </Col>
        </Row>
      )}
    </StyledDrawer>
  );
};

export default ScenarioDrawer;
