import { useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, notification, Row } from 'antd';
import Lottie from 'lottie-react';

import { scenarioApi } from '@/api';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import Card from '@/components/RCard';
import RNotificationCard from '@/components/RNotificationCard';
import Steps from '@/components/RSteps';
import { AnimatedText } from '@/components/RTextAnimation';
import RUsageLimitIndicator from '@/components/RUsageLimitIndicator';
import { Draft } from '@/types/global';
import { ScenarioForm } from '@/types/scenario';
import { handleError } from '@/utils';

import { StyledDrawer } from './styles';
import BatteryOptions from '../Form/BatteryOptions';
import Description from '../Form/Description';
import ElectricityMarketParticipation from '../Form/ElectricityMarketParticipation';
import FinancialMetrics from '../Form/FinancialMetrics';
import PricesAndLoadProfile from '../Form/PricesAndLoadProfile';

type Props = {
  open: boolean;
  setIsOpen: (value: boolean) => void;
  mutate: () => void;
  maxDataCount: number;
  currentDataCount: number;
};

const ScenarioCreateDrawer = (props: Props) => {
  const { open, setIsOpen, mutate, maxDataCount, currentDataCount } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [draft, setDraft] = useState<Draft>({
    draftId: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const [uploadError, setUploadError] = useState<string | null>('');

  const hasUnsavedData = () => {
    const fields = form.getFieldsValue(true);
    return Object.values(fields).some((item) => item !== undefined);
  };

  const onClose = () => {
    if (hasUnsavedData()) {
      setNotificationCardVisible(true);
    } else {
      setIsOpen(false);
    }
  };

  const resetDrawerData = () => {
    setCurrentStep(0);
    form.resetFields();
  };

  const steps = [
    {
      key: 'first',
      title: 'Description',
      content: <Description />,
    },
    {
      key: 'second',
      title: 'Electricity Market Participation',
      content: <ElectricityMarketParticipation form={form} />,
    },
    {
      key: 'third',
      title: 'Battery Options',
      content: <BatteryOptions form={form} />,
    },
    {
      key: 'fourth',
      title: 'Financial Metrics',
      content: <FinancialMetrics />,
    },
    {
      key: 'fifth',
      title: 'Prices and Load Profile',
      content: (
        <PricesAndLoadProfile
          form={form}
          draft={draft}
          setDraft={setDraft}
          uploadError={uploadError}
          setUploadError={setUploadError}
        />
      ),
    },
  ];

  const nextStep = async () => {
    if (currentStep < steps.length - 1) {
      try {
        const values = await form.validateFields();
        form.setFieldsValue(values);
        setCurrentStep((prev) => prev + 1);
      } catch (error) {
        console.error('Validation failed:', error);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const onFinish = async () => {
    const values: ScenarioForm = form.getFieldsValue(true);
    if (!values.name) {
      throw new Error('Some required values are undefined.');
    }
    try {
      setIsLoading(true);
      await scenarioApi.createScenario({
        name: values.name,
        //pricesAndLoad: values.pricesAndLoad
        draftId: draft.draftId,
        sellToGrid: values.gridActions?.includes('sellToGrid'),
        purchaseFromGrid: values.gridActions?.includes('purchaseFromGrid'),
        unitDegradation: values.unitDegradation,
        whenBatteryIsDepleted: values.whenBatteryIsDepleted,
        replacementNumberLimit: values.replacementNumberLimit,
        modelType: values.modelType,
        taxRate: values.taxRate,
        batteryPriceDeclinePercentagePerYear:
          values.batteryPriceDeclinePercentagePerYear,
        hurdleRate: values.hurdleRate,
        pfcParticipation: values.pfcParticipation,
        sfcParticipation: values.sfcParticipation,
      });
      notification.success({
        message: `${values.name} scenario has been created successfully!`,
      });
      setIsLoading(false);
      resetDrawerData();
      mutate();
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      console.log('error', error);

      handleError(error);
      setIsLoading(false);
    }
  };

  //TODO Split this into a separate component
  const overlayTextArray = useMemo(() => {
    return [
      'Configuring scenario settings.',
      'Analyzing file...',
      'The final touches are being made.',
    ];
  }, []);

  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, isLoading]);

  return (
    <StyledDrawer
      title="Create New Scenario"
      description="Add a new scenario to the library by providing its details below."
      destroyOnClose
      onClose={onClose}
      open={open}
      rightSideSection={<RUsageLimitIndicator.Scenarios />}
      drawerLock={{
        isLocked: !!(currentDataCount === maxDataCount),
        lockText: 'You have reached the limit of scenarios you can create.',
      }}
    >
      <Card $borderColor="transparent" className="px-16 pt-16 ">
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Steps
            className="mb-24"
            current={currentStep}
            items={steps}
            type="default"
          />
          <div>{steps[currentStep].content}</div>
          <Row
            className="bottom-button-group"
            align="middle"
            justify="space-between"
          >
            <Button onClick={onClose} className="mr-8">
              Cancel
            </Button>
            <div className="right-side-btn-group">
              {currentStep > 0 && (
                <Button className="mr-4" onClick={prevStep}>
                  Previous
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button className="ml-4" onClick={nextStep} type="primary">
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button className="ml-4" type="primary" htmlType="submit">
                  Create
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </Card>
      <RNotificationCard
        title="Data will be lost."
        message="Are you sure you want to leave?"
        type="warning"
        confirmText="Leave"
        cancelText="Cancel"
        open={notificationCardVisible}
        onConfirm={() => {
          resetDrawerData();
          setNotificationCardVisible(false);
          setIsOpen(false);
        }}
        onCancel={() => setNotificationCardVisible(false)}
      />
      {isLoading && (
        <Row align="top" justify="center" className="pt-120 loading-wrapper">
          <Col>
            <Lottie
              style={{ height: '120px' }}
              animationData={loadingLottie}
              loop={true}
            />
            <Row>
              <AnimatedText className="animated-text" text={overlayText} />
            </Row>
          </Col>
        </Row>
      )}
    </StyledDrawer>
  );
};

export default ScenarioCreateDrawer;
