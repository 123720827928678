import { ChangeEvent, FC, useEffect } from 'react';

import { Col, Divider, Flex, Form, Input, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import { useTheme } from 'styled-components';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import RTooltip from '@/components/RTooltip';
import { StorageSystemForm } from '@/types/storageSystem';
import {
  formatInputNumber,
  normalizePositiveIntegerValuesInput,
  normalizePositiveNumericValuesInput,
  removeCommas,
} from '@/utils';

type Props = {
  disabled?: boolean;
  form: FormInstance<StorageSystemForm>;
  initialValues?: StorageSystemForm;
};

const { Option } = Select;

const FinancialMetrics: FC<Props> = ({
  disabled = false,
  form,
  initialValues,
}) => {
  const theme = useTheme();

  const initialYearCount = form.getFieldValue('investmentLag');

  const initialPreopCapexDistribution = form.getFieldValue(
    'preopCapexDistribution'
  );
  const yearCount = Form.useWatch('investmentLag', form) || initialYearCount;
  const preopCapexDistribution =
    Form.useWatch('preopCapexDistribution', form) ||
    initialPreopCapexDistribution;

  useEffect(() => {
    if (form) {
      if (initialValues?.name) {
        const initialYearCount = initialValues.investmentLag;
        if (yearCount != initialYearCount) {
          const year = Number(yearCount) + 1;
          form.setFieldValue('preopCapexDistribution', Array(year).fill(''));
        }
        if (yearCount == initialYearCount) {
          form.setFieldValue(
            'preopCapexDistribution',
            initialValues.preopCapexDistribution
          );
        }
      } else {
        const year = Number(yearCount) + 1;
        if (form.isFieldTouched('investmentLag')) {
          form.setFieldValue('preopCapexDistribution', Array(year).fill(''));
        }
      }
    }
  }, [form, initialValues, yearCount]);

  useEffect(() => {
    if (form && preopCapexDistribution) {
      const validateTotal = () => {
        // Calculate the total value
        const total = preopCapexDistribution.reduce(
          (sum: number, item: string) => {
            const value = parseFloat(item) || 0;
            return sum + value;
          },
          0
        );

        if (total !== 100) {
          // Set form errors if the total is not exactly 100
          form.setFields(
            preopCapexDistribution.map((_: unknown, index: number) => ({
              name: [`preopCapexDistribution`, index],
              errors: [
                total > 100
                  ? 'The total value of Pre-Operation Years cannot exceed 100.'
                  : 'The total value of Pre-Operation Years must be exactly 100.',
              ],
            }))
          );
        } else {
          // Clear errors if total is exactly 100
          form.setFields(
            preopCapexDistribution.map((_: unknown, index: number) => ({
              name: [`preopCapexDistribution`, index],
              errors: [],
            }))
          );
        }
      };
      validateTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preopCapexDistribution]);

  const handleNumberChange =
    (field: keyof StorageSystemForm) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value.replace(/[^0-9.]/g, '');
      const formatted = formatInputNumber(rawValue);
      form?.setFieldValue(field, formatted);
    };

  const handleNumberBlur = (field: keyof StorageSystemForm) => () => {
    const value = form?.getFieldValue(field) || '';
    form?.setFieldValue(field, removeCommas(value));
  };

  const energyStorageComponentsUnitOptions = (
    <Form.Item<StorageSystemForm>
      style={{ margin: '0px' }}
      name="batteryCapexUnit"
      initialValue="dollars"
    >
      <Select disabled={disabled} style={{ width: 90 }}>
        <Option value="dollars">$</Option>
        <Option value="dollars_per_mw">$/MWh</Option>
      </Select>
    </Form.Item>
  );

  const pcsCostUnitOptions = (
    <Form.Item<StorageSystemForm>
      initialValue="dollars"
      style={{ margin: '0px' }}
      name="pcsCostUnit"
    >
      <Select disabled={disabled} style={{ width: 90 }}>
        <Option value="dollars">$</Option>
        <Option value="dollars_per_mw">$/MW</Option>
      </Select>
    </Form.Item>
  );

  const bosCostUnitOptions = (
    <Form.Item<StorageSystemForm>
      style={{ margin: '0px' }}
      name="bosCostUnit"
      initialValue="dollars"
    >
      <Select disabled={disabled} style={{ width: 90 }}>
        <Option value="dollars">$</Option>
        <Option value="dollars_per_mw">$/MW</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form.Item
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16"
      wrapperCol={{ span: 24 }}
    >
      <Row className="mb-24">
        <Col className="pr-8" span={12}>
          <Form.Item<StorageSystemForm>
            name="investmentLag"
            label={
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-regular text-black-color">
                  Duration of Pre-Operational Phase
                </Typography.Text>
                <RTooltip
                  title="Duration of Pre-Operational Phase"
                  description="This input indicates the number of years the Power Plant is under construction and cannot operate. It represents the time before the plant starts being productive."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            }
            normalize={normalizePositiveIntegerValuesInput}
            rules={[
              {
                required: true,
                message: 'Duration of Pre-Operational Phase is required',
              },
              {
                validator(_, value) {
                  const minValue = parseFloat(value);
                  if (isNaN(minValue) || minValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (minValue > 100) {
                    form?.setFieldsValue({ investmentLag: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="number"
              disabled={disabled}
              placeholder="Duration of Pre-Operational Phase"
              addonAfter="Year"
            />
          </Form.Item>
          {yearCount > 0 && (
            <Col className="mb-24">
              <Divider
                dashed
                style={{
                  marginBottom: '-12px',
                  borderColor: theme.colors.grayLightAccent,
                }}
                orientation="left"
              >
                <Typography.Text>CAPEX Distribution by Year</Typography.Text>
              </Divider>
              <Row className="preop-capex">
                <Form.List
                  name="preopCapexDistribution"
                  initialValue={Array(yearCount).fill({})}
                >
                  {(subFields) => {
                    const isOperationInitiation = 0;
                    const reversedKeys = subFields
                      .map((item) => item.key)
                      .reverse();

                    const updatedSubFields = subFields.map((item, index) => ({
                      ...item,
                      key: reversedKeys[index],
                    }));

                    return (
                      <Row gutter={[12, 12]}>
                        {updatedSubFields.map((subField) => (
                          <Col span={12} key={subField.key}>
                            <Form.Item
                              key={`preOperationYear-${subField.key}`}
                              name={subField.name}
                              label={
                                <Flex gap={8} align="center">
                                  <Typography.Text className="fs-14-regular text-black-color">
                                    {subField.key === isOperationInitiation
                                      ? 'Operation Initiation Year'
                                      : `Pre-Operation Year ${subField.key}`}
                                  </Typography.Text>
                                  <RTooltip
                                    title={
                                      subField.key === isOperationInitiation
                                        ? 'Operation Initiation Year'
                                        : `Pre-Operation Year ${subField.key}`
                                    }
                                    description={
                                      subField.key === isOperationInitiation
                                        ? 'The year in which the storage system begins operating'
                                        : `${subField.key} Year Before Operation Starts`
                                    }
                                  >
                                    <IconInfo />
                                  </RTooltip>
                                </Flex>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: `Pre-Operation Year ${subField.key} is required`,
                                },
                                {
                                  validator(_, value) {
                                    const minValue = parseFloat(value);
                                    if (isNaN(minValue) || minValue < 0) {
                                      return Promise.reject(
                                        new Error(
                                          'Value must be between 0 and 100.'
                                        )
                                      );
                                    } else if (minValue > 100) {
                                      return Promise.reject(
                                        new Error(
                                          'Value must be between 0 and 100.'
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input
                                disabled={disabled}
                                placeholder={`Enter Pre-Operation Year ${subField.key}`}
                                addonAfter="%"
                              />
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    );
                  }}
                </Form.List>
              </Row>
            </Col>
          )}
        </Col>
        <Col span={12} className="pl-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="bosLifespan"
            label="Operational Lifespan of Balance of System"
            normalize={normalizePositiveNumericValuesInput}
            tooltip={{
              title: (
                <div>
                  <>
                    <Typography.Text className="fs-14-semi-bold">
                      Operational Lifespan of Balance of System
                    </Typography.Text>
                    <div className="horizontal-line mt-4"></div>
                  </>
                  <Typography.Text className="fs-14-regular smoke mt-4">
                    After this period, the Balance of System is no longer
                    functional and must be decommissioned.
                  </Typography.Text>
                </div>
              ),
              overlayInnerStyle: {
                padding: theme.spacing.spacing12 + 'px',
                color: 'inherit',
              },
              color: theme.colors.light,
            }}
            rules={[
              {
                required: true,
                message:
                  'Operational Life Span of Balance of System is required.',
              },
            ]}
          >
            <Input
              addonAfter="Year"
              disabled={disabled}
              placeholder="Operational Life Span of Balance of System"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col span={12} className="pr-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="depreciationYearsBattery"
            label="Depreciation Years for Energy Storage Components"
            rules={[
              {
                required: true,
                message:
                  'Depreciation Years for Energy Storage Components is required.',
              },
            ]}
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              addonAfter="Year"
              disabled={disabled}
              placeholder="Depreciation Years for Energy Storage Components"
            />
          </Form.Item>
        </Col>
        <Col span={12} className="pl-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="depreciationYearsBos"
            label="Depreciation Years for Storage Balance System"
            rules={[
              {
                required: true,
                message:
                  'Depreciation Years for Storage Balance System is required.',
              },
            ]}
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              addonAfter="Year"
              disabled={disabled}
              placeholder="Depreciation Years for Storage Balance System"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} className="pr-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="batteryCapexValue"
            label="Energy Storage Components"
            rules={[
              {
                required: true,
                message: 'Energy Storage Components is required.',
              },
            ]}
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Energy Storage Components"
              addonAfter={energyStorageComponentsUnitOptions}
              onChange={handleNumberChange('batteryCapexValue')}
              onBlur={handleNumberBlur('batteryCapexValue')}
            />
          </Form.Item>
        </Col>
        <Col span={8} className="pl-8 pr-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="pcsCostValue"
            label="Power Conversion Components"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Power Conversion Components is required.',
              },
            ]}
          >
            <Input
              addonAfter={pcsCostUnitOptions}
              disabled={disabled}
              placeholder="Power Conversion Components"
              onChange={handleNumberChange('pcsCostValue')}
              onBlur={handleNumberBlur('pcsCostValue')}
            />
          </Form.Item>
        </Col>
        <Col span={8} className="pl-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="bosCostValue"
            label="Balanced of System"
            rules={[
              {
                required: true,
                message:
                  'Depreciation Years for Storage Balance System is required.',
              },
            ]}
          >
            <Input
              addonAfter={bosCostUnitOptions}
              disabled={disabled}
              placeholder="Depreciation Years for Storage Balance System"
              onChange={handleNumberChange('bosCostValue')}
              onBlur={handleNumberBlur('bosCostValue')}
            />
          </Form.Item>
        </Col>
        <Col span={8} className="pr-8">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="fixedOpex"
            label="OPEX per year"
            rules={[
              {
                required: true,
                message: 'OPEX per year is required.',
              },
            ]}
          >
            <Input
              addonAfter="$"
              disabled={disabled}
              placeholder="OPEX per year"
              onChange={handleNumberChange('fixedOpex')}
              onBlur={handleNumberBlur('fixedOpex')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default FinancialMetrics;
